<template>
  <div class="shop">
    <!-- 标题 -->
    <!-- <div>
      <van-sticky>
        <van-nav-bar title="便民商店" />
      </van-sticky>
    </div> -->
    <!-- 搜索 -->

    <van-sticky :offset-top="0">
      <van-sticky>
        <van-tabs @click="onClick"
          color="#fb4d3f"
          title-active-color="#fb4d3f"
          swipeable
          swipe-threshold="4">
          <van-tab :name="0"
            title="全部">
          </van-tab>
          <van-tab v-for="item in TitleList"
            :key="item.STypeId"
            :name="item.STypeId"
            :title="item.Name">
          </van-tab>
        </van-tabs>
      </van-sticky>
      <div>
        <van-search v-model="search"
          show-action
          placeholder="请输入搜索关键词"
          @search="onSearch">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
    </van-sticky>
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list v-model="loading"
        :finished="finished"
        @load="onLoad"
        style="margin-bottom: 50px;"
        :offset="1"
        :immediate-check="false">
        <div class="center"
          v-for="item in list"
          :key="item.index">
          <div class="shopKind">
            {{ item.ShopCateDesc }}
          </div>
          <!-- <router-link :to="'/article/detail/' + item.RIId"> -->
          <div class="shopTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content"
            style="padding: 8px 10px">
            <div style="display: inline-block"
              class="ContentA">
              <van-image style="width: 100%; height: 85px"
                :src="item.ShopImgUrl">
                <template style="width: 100%; height: 85px"
                  v-slot:error>
                  <img src="http://lccgzapi.zhsq.cloud//upload/images/2023/3/3205de2b-c04.png"
                    alt="" />
                </template>
              </van-image>
            </div>
            <div style="display: inline-block"
              class="ContentB">
              <span style="color: #333"
                class="van-multi-ellipsis--l3">
                <van-icon name="location" />{{ item.Address }}</span>
              <br />
              <span style="font-size: 12px; color: #333">{{ item.IName }}</span>
              <br />
              <span class="centertime"
                style="color: #333">
                <van-icon name="manager" />{{ item.Charger }}
                <van-icon name="phone" /><a :href="'tel:' + item.Mobile"
                  style="color:black;">{{ item.Mobile }}</a>
              </span>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { WeGetShopPage, WxGetShopTypeList } from "@/api/RealInfo";
export default {
  data () {
    return {
      sTypeId: 0, //tab栏
      list: [], //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      TitleList: [],
    };
  },
  methods: {
    // 获取商铺分类
    getShopTypeList () {
      WxGetShopTypeList().then((res) => {
        if (res.data.code == 0) {
          this.TitleList = res.data.data
        }
      })
    },
    // 获取分页列表
    getList () {
      this.listfrom.shopCate = 1;
      this.listfrom.sTypeId = this.sTypeId;
      this.listfrom.title = this.search;
      WeGetShopPage(this.listfrom)
        .then((res) => {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束
          this.loading = false;

          if (res.data.code == 0) {
            if (rows == null || rows.length === 0) {
              // 加载结束
              this.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.list = this.list.concat(rows);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.list.length >= res.data.count) {
              this.finished = true;
            }
          }
        })
        .catch(() => { });
    },
    // 下拉刷新
    onLoad () {
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.list = [];
      this.sTypeId = e;
      this.listfrom.page = 1;
      this.finished = false;
      this.getList();
    },
    // 搜索
    onSearch () {
      this.list = [];
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    this.getShopTypeList()
    this.getList();
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
  width: 70%;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: #f8f8f8;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.shopKind {
  position: absolute;
  right: 20px;
  background: #387bff;
  border-radius: 0px 8px 0px 8px;
  /* line-height: 28px; */
  padding: 5px 8px;
  color: white;
}
</style>